<!--
 * @Description: 车行道停车运营管理 运营管理 停车记录 parkingRecords
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="泊位号">
              <el-input v-model="searchForm.parkSpaceNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="入场时间">
              <el-date-picker v-model="inTime"
                              type="daterange"
                              align="right"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="离场时间">
              <el-date-picker v-model="outTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>停车记录</span>
        <!-- <el-button type="info"
                   class="tableTitleButton">导出</el-button> -->
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
import duration from '@/utils/duration'
export default {
  components: { dtTable },
  data () {
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15 },
      tableList: { list: [] },
      searchForm: { orderStatusCode: 2 },
      pageNum: 1,
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'orderSequence',
          label: '订单号',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'parkSpaceNumber',
          label: '泊位号',
        },
        {
          prop: 'inTime',
          label: '进场时间',
        },
        {
          prop: 'outTime',
          label: '离场时间',
        },
        {
          prop: 'parkDuration',
          label: '停车时长',
        },
      ],
      inTime: null, //进场时间
      outTime: null, //出场时间
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList() //查询表格数据
  },
  //方法集合
  methods: {
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$parkingRecords.parkRecord(this.searchForm).then((response) => {
        if (response.resultEntity) {
          this.tableList.list = response.resultEntity.content
          this.paginationConfig.total = response.resultEntity.totalElements
          this.tableList.list.forEach(item => {
            item.parkDuration = duration(item.parkDuration)
          });
        }
        else {
          this.tableList.list = []
        }
      })
    },
    //查询按钮查询
    queryButton () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      if (this.inTime) {
        this.searchForm.inStartTime = this.inTime[0]
        this.searchForm.inEndTime = this.inTime[1]
      } else {
        this.searchForm.inStartTime = ''
        this.searchForm.inEndTime = ''
      }
      if (this.outTime) {
        this.searchForm.outStartTime = this.outTime[0]
        this.searchForm.outEndTime = this.outTime[1]
      } else {
        this.searchForm.outStartTime = ''
        this.searchForm.outEndTime = ''
      }
      this.queryTableList()
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList();
    },
    //重置
    resetForm () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.searchForm = {}
      this.inTime = []
      this.outTime = []
      this.queryTableList()
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
